html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
/* End of Eric Meyer's CSS Reset */

html {
  height: 100%;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}
body {
  font-family: 'Avenir Next Cyr', sans-serif;
  font-size: 14px;
  line-height: 1;
  color: #243949;
  height: 100%;
}
body.not-scroll{
  overflow: hidden;
}
textarea,input[type=text],input[type=email],input[type=password],input[type=search],input[type=tel]{
  font-family: 'Avenir Next Cyr', sans-serif;
  border: 1px solid #666;
  background: #fff;
  color: #000;
  outline: none;
}
input[type=submit], button {
  margin: 0;
  -webkit-appearance: none;
  font-family: 'Avenir Next Cyr', sans-serif;
  outline: none;
  cursor: pointer;
}
.inner {
  min-width: 280px;
  max-width: 1170px;
  margin: 0 auto;
}
.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
a {
  color: #243949;
  text-decoration: underline;
  outline: none;
}
a:hover {
  text-decoration: none;
}
.slick-slide{
  outline: none !important;
}
img {
  display: block;
  max-width: 100%;
  height: auto;
}
:invalid {
  -webkit-box-shadow: none;
  box-shadow: none;
}
strong{
  font-weight: bold;
}
em{
  font-style: italic;
}