@import "var";

@font-face {
  font-family: 'Oswald';
  src: local('Oswald'), url('../../../fonts/Oswald-Light.woff') format('woff');
}

/* Header
-----------------------------------------------------------------------------*/
.site-header{
  background: url("../../../images/head-back.jpg") no-repeat center / cover;
}
.header-top{
  border-bottom: 1px solid rgba(255,255,255,0.1);
}
.header-top__container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
}
.header-top__left{
  margin-right: auto;
}
.header-menu ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.header-menu li{
  margin: 12px 51px 12px 0;
}
.header-menu a{
  font-size: 14px;
  color: #a7a8a9;
  text-decoration: none;
  -webkit-transition: color 0.1s ease-out 0s;
  -o-transition: color 0.1s ease-out 0s;
  transition: color 0.1s ease-out 0s;
}
.header-menu a:hover{
  color: #3d5aff;
}
.header-top__right{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-social{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.header-social a{
  display: block;
  margin-right: 29px;
}
.header-social svg{
  max-width: 30px;
  height: 20px;
  fill: #fff;
  -webkit-transition: fill 0.1s ease-out 0s;
  -o-transition: fill 0.1s ease-out 0s;
  transition: fill 0.1s ease-out 0s;
}
.header-social a:hover svg{
  fill: #3d5aff;
}
.header-social .social-3 svg{
  height: 30px;
}
.header-login{
  border-left: 1px solid rgba(255,255,255,0.1);
}
.header-login a{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 29px;
  color: #a7a8a9;
  text-decoration: none;
  -webkit-transition: color 0.1s ease-out 0s;
  -o-transition: color 0.1s ease-out 0s;
  transition: color 0.1s ease-out 0s;
}
.header-login a:hover{
  color: #3d5aff;
}
.header-login a svg{
  width: 20px;
  height: 20px;
  fill: #fff;
  margin-right: 12px;
  -webkit-transition: fill 0.1s ease-out 0s;
  -o-transition: fill 0.1s ease-out 0s;
  transition: fill 0.1s ease-out 0s;
}
.header-login a:hover svg{
  fill: #3d5aff;
}
.header-login a span{
  display: block;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.header-search{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 15px;
  border-left: 1px solid rgba(255,255,255,0.1);
}
.header-search__handler{
  padding: 9px 0 9px 15px;
  cursor: pointer;
}
.header-search__handler svg{
  width: 20px;
  height: 20px;
  fill: #fff;
}
.search-form{
  width: calc(100% + 30px);
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 0;
  overflow: hidden;
  opacity: 0;
  background: #243949;
  position: absolute;
  top: -45px;
  right: -15px;
  -webkit-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border-left: 1px solid rgba(255,255,255,0.1);
  border-right: 1px solid rgba(255,255,255,0.1);
  z-index: 1;
}
.search-form.opened{
  top: 0;
  overflow: visible;
  opacity: 1;
}
.search-form input[type=text]{
  width: calc(100% - 50px);
  height: 30px;
  background: no-repeat;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 15px;
  font-size: 14px;
  color: #fff;
  border: none;
}
.search-form button[type=submit]{
  display: none;
}
.search-form__close{
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 6px;
  cursor: pointer;
}
.search-form__close svg{
  width: 14px;
  height: 14px;
  fill: #fff;
}
.header-main__container{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;/*
	align-items: center;*/
  padding: 20px 0px;
}
.header-main__left{
  -ms-flex-negative: 0;
  flex-shrink: 0;
  padding: 5px 30px 0 0;
}
.header-logo{
  display: block;
  text-decoration: none;
}
.header-logo__image img{
  margin-bottom: -1px;
}
.header-logo__text{
  font-size: 14px;
  font-weight: bold;
  color: #3d5aff;
  letter-spacing: 2.9px;
}
.header-nav{
  padding: 8px 0 13px;
  border-top: 1px solid rgba(255,255,255,0.1);
  background: rgba(255,255,255,0.1);
}
.header-nav ul{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 -10px;
  font-family: 'Oswald', serif;
  font-size: 18px;
  font-weight: 300;
}
.header-nav a{
  display: block;
  padding: 10px 10px;
  color: #fff;
  text-decoration: none;
  -webkit-transition: color 0.1s ease-out 0s;
  -o-transition: color 0.1s ease-out 0s;
  transition: color 0.1s ease-out 0s;
}
.header-nav a:hover{
  color: #3d5aff;
}
.touch-menu{
  display: none;
}